




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InformationProject extends Vue {
  @Prop(String) aboutInfo!: any
}
