











import { Vue, Component, Prop } from 'vue-property-decorator'
import gsap from 'gsap'

@Component
export default class StatisticCard extends Vue {
  @Prop() readonly headerNumber!: number
  @Prop() readonly subheader!: string
  private tweenNumber = 0

  get animatedHeaderNumber() {
    return this.tweenNumber.toFixed(0)
  }
  private created() {
    gsap.to(this.$data, { duration: 0.5, tweenNumber: this.headerNumber })
  }
}
