




































































































import { Component, Vue } from 'vue-property-decorator'
import { clientBase } from '@/api/requests/httpAxios'
import InformationProject from '@/components/common/InformationProject.vue'
import StatisticCard from '@/components/statitics/StatisticCard.vue'
import ContentPreview from '@/components/content/ContentPreview.vue'
import ContentAPI from '@/api/requests/content'
import AdminApi from '@/api/requests/admin'
import { contentStatus } from '@/config/content/contentStatus'
import { newsOnPage, eventsOnPage } from '@/config/content/contentCount'
import { userMapper } from '@/store/modules/user'
import { navBarTitleMapper } from '@/store/modules/navbar'
import Config from '@/config/default/config'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isTeacher'])
  },
  methods: {
    ...navBarTitleMapper.mapMutations(['setTitle', 'setSubTitle'])
  }
})
@Component({
  components: {
    ContentPreview,
    InformationProject,
    StatisticCard
  }
})
export default class MainPage extends Mapper {
  private aboutInfo = ''
  private eventObjects: object[] = []
  private newsObjects: object[] = []
  private courseObjects: object[] = []

  private clientBase_ = clientBase

  private redirectToOrganizations() {
    this.$router.push('/organizations')
  }
  private changeDisplayStatus(contentObjects: object[]) {
    contentObjects.forEach((element: any) => {
      element.status = false
    })
  }
  private fetchedStatsFromServer = {
    enrolledInUniversity: 887,
    organizationsInProject: 32,
    activeStudents: 1025
  }
  private async created() {
    this.setTitle(Config.systemTitle.fullName)
    this.setSubTitle('')

    const response = (await AdminApi.getAboutProjectInfo()).data

    this.aboutInfo = response.aboutProjectInfo
    this.fetchedStatsFromServer.organizationsInProject =
      response.organizationsInProjectCount
    this.fetchedStatsFromServer.enrolledInUniversity =
      response.enrolledInUniversityCount
    this.fetchedStatsFromServer.activeStudents = response.activeStudentsCount

    this.newsObjects = (
      await ContentAPI.getNews(newsOnPage, 0, contentStatus.publicated)
    ).data.rows
    this.changeDisplayStatus(this.newsObjects)

    if (this.isTeacher) {
      this.eventObjects = (
        await ContentAPI.getEvents(eventsOnPage, 0, contentStatus.all)
      ).data.rows
    } else {
      this.eventObjects = (
        await ContentAPI.getEvents(eventsOnPage, 0, contentStatus.publicated)
      ).data.rows
    }
    this.changeDisplayStatus(this.eventObjects)

    this.courseObjects = (await ContentAPI.getLastFiveCourses()).data
  }

  private toNews() {
    this.$router.push('/news')
  }
  private toEvents() {
    this.$router.push('/events')
  }
}
