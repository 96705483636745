import moment from 'moment'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { arrayBufferToBlob } from 'blob-util'
import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'
import dateFormat from '@/config/default/dateFormat'

export default class AdminAPI {
  public static setUser(user: any, userId: number): AxiosPromise {
    return http.put(`/admin/users/${userId}/edit`, user)
  }

  public static addUser(data: any): AxiosPromise {
    return http.post('/admin/users/invite', data)
  }

  public static inviteUserByRole(data: any, role: string): AxiosPromise {
    return http.post(`/admin/list/invite?inviteRole=${role}`, data)
  }

  public static getUsers(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null,
    scopes: string[] = ['onlyActivated', 'notActivated']
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter)
    for (const scope of scopes) {
      params.append('scopes', scope)
    }
    return http.get('/users', { params })
  }

  public static deleteUser(userId: number): AxiosPromise {
    return http.delete(`admin/users/${userId}`)
  }

  public static resetUser(email: string): AxiosPromise {
    return http.put(`/admin/users/password`, { email })
  }

  public static getAboutProjectInfo(): AxiosPromise {
    return http.get(`/about`)
  }

  public static updateAboutProjectInfo(about: string): AxiosPromise {
    return http.put(`/about`, { about })
  }

  public static getActivity(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter)

    return http.get('/logs/client', { params })
  }

  public static getSessions(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, { filter })

    return http.get('/admin/sessions', { params })
  }

  static async downloadDump() {
    const response = await http.post('/admin/dump', null, {
      responseType: 'arraybuffer'
    })
    const filename = response?.headers?.['content-disposition']
      ? response.headers['content-disposition'].split('=')[1]
      : `${moment(new Date()).format(
          dateFormat.date.serverFormat
        )}-trajectories.dump`
    saveAs(arrayBufferToBlob(response.data), filename)
  }

  public static setMessage(
    message: string,
    time?: string,
    showReload = false,
    save = false
  ): AxiosPromise {
    const params = new URLSearchParams()
    if (save) {
      params.append('save', 'true')
    }
    return http.post(
      '/admin/maintenance',
      {
        message,
        ...(_.isEmpty(time) ? { time: null } : { time }),
        showReload
      },
      { params }
    )
  }

  public static clearMessage() {
    const params = new URLSearchParams()
    params.append('save', 'true')
    return http.post('/admin/maintenance', null, { params })
  }
}
